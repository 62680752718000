<template>
  <div>
    <h3 ref="what">
      Bar Chart
    </h3>
    <p class="mt-2">
      A bar chart is commonly used to represent the grouping of data into different categories.
      The categories could be something like an age group, months of the year, or geographical locations.
      In a bar chart (also known as a bar graph), the data is represented using a number of bars. Each bar represents a particular category or group.
      The height of a bar is proportional to the sum total of entries in the category it represents.
    </p>
    <h3 ref="example">
      Example of a Bar Chart
    </h3>
    <p class="mt-2">
      Imagine you are at a dog show. The show features dogs of a variety of breeds, e.g., 2 Bulldogs, 3 Dobermans, 4 Greyhounds, 1 Dalmatian, and 2 Shar-Peis.
      Below is a bar chart summarizing this data graphically. Shown on the horizontal axis is the breed of dogs.
      Shown on the vertical axis is number of dogs.
      Each bar represents a dog breed and the height of the bar is equal to the number of dogs of the breed that it represents.
      For example, the bar representing Dobermans has a height of 3 units, while the bar representing Greyhounds is 4 units tall.
    </p>
    <v-layout justify-center>
      <v-img src="/assets/bargraph.png"
             max-height="450px"
             max-width="450px"
             contain
      />
    </v-layout>
    <h5 style="text-align:center">
      Figure: Example of a bar chart displaying distribution of dogs of different breeds.
    </h5>
    <br>
    <h3 ref="playgraph">
      MagicGraph &vert; Bar Chart
    </h3>
    <p>
      This MagicGraph below offers a visually interactive module to help you learn how to read and use bar charts.
    </p>
    <h5> To Get Started </h5>
    <p>
      You are given a triangle formed by points O, A and B.
      The vertices O, A, and B of the triangle can be dragged and moved around to change
      the type (i.e., acute, obtuse or right) and size of the triangle.
      You will be able to see that the three interior angles &alpha;, &beta; and &gamma; change as the vertices are dragged around.
    </p>
    <h5> To Explore</h5>
    <p>
      Tap on the shuffle button to generate different data sets. The MagicGraph will automatically adjust the bar chart to represent that data.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <h3 ref="playgraph2">
      MagicGraph &vert; Test Your Knowledge of Bar Chart
    </h3>
    <p>
      This MagicGraph offers a visually interactive way to test your knowledge of bar charts.
    </p>
    <h5> To Get Started: </h5>
    <p>
      You are given a triangle formed by points O, A and B.
      The vertices O, A, and B of the triangle can be dragged and moved around to change
      the type (i.e., acute, obtuse or right) and size of the triangle.
      You will be able to see that the three interior angles &alpha;, &beta; and &gamma; change as the vertices are dragged around.
    </p>
    <h5> To Explore:</h5>
    <p>
      Drag points O, A and B of the triangle to change the type (i.e., acute, obtuse or right) and size of the triangle OAB.
      Then, tap on the two scissors to cut out the angles &alpha; and &beta;.
      No matter what type or size of the triangle you choose, the three interior
      angles &alpha;, &beta; and &gamma; always form a half circle when stacked adjacent to each other.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox2" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Angles',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Visualizing Data';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'complementary Angle', img:'/assets/number-1.svg', action: () => this.goto('ca')},
      {title: 'Supplementary Angle', img:'/assets/number-2.svg', action: () => this.goto('sa')},
      {title: 'Special Remarks', img:'/assets/number-3.svg', action: () => this.goto('ra')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Boxes.box2();
  },
  metaInfo() {
  return{ title: 'Bar Chart',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
